import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-daterange', 'TODAY', 'Šiandien');
Labels.set('ui-daterange', 'YESTERDAY', 'Vakar');
Labels.set('ui-daterange', 'CURRENT_WEEK', 'Ši savaitė');
Labels.set('ui-daterange', 'CURRENT_MONTH', 'Šis mėnuo');
Labels.set('ui-daterange', 'CURRENT_YEAR', 'Nuo metų pradžios');
Labels.set('ui-daterange', 'PREV_YEAR', 'Praėję metai');
Labels.set('ui-daterange', 'PREV_WEEK', 'Praėjusi savaitė');
Labels.set('ui-daterange', 'PREV_MONTH', 'Praėjęs mėnuo');
Labels.set(
    'ui-daterange',
    'PREV_MONTH_TODAY',
    'Praėjęs mėnuo ir einamasis mėnuo'
);
Labels.set('ui-daterange', 'PERIOD_START', 'Laikotarpio pradžia');
Labels.set('ui-daterange', 'PERIOD_END', 'Laikotarpio pabaiga');
Labels.set('ui-daterange', 'TIME_START', 'Laiko pradžia');
Labels.set('ui-daterange', 'TIME_END', 'Laiko pabaiga');
