import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-comparison-cards', 'compareDetails', 'Palyginti detales');
Labels.set('ui-comparison-cards', 'available', 'Galimas');
Labels.set('ui-comparison-cards', 'notAvailable', 'Negalimas');
Labels.set('ui-comparison-cards', 'comparisonItem1', 'Palyginimo objektas 1');
Labels.set('ui-comparison-cards', 'comparisonItem2', 'Palyginimo objektas 2');
Labels.set(
    'ui-comparison-cards',
    'selectProductsCompare',
    'Pasirinkite, kuriuos produktus palyginti.'
);
