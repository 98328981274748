/* TODO: translate */

import { Labels } from '../../shared-components/global/labels.js';

Labels.set(
    'ui-autocomplete',
    'hint',
    'Use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.'
);
Labels.set('ui-autocomplete', 'results', 'Results');
Labels.set('ui-autocomplete', 'resultsFound', 'Results found: ');
Labels.set('ui-autocomplete', 'noResultsFound', 'No results found');
