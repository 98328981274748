import { Labels } from '../../shared-components/global/labels.js';

Labels.set('validation', 'input', 'Įveskite teisingą reikšmę.');
Labels.set('validation', 'date', 'Įveskite teisingą reikšmę.');
Labels.set('validation', 'time', 'Įveskite laiką.');
Labels.set('validation', 'range', 'Neteisingai įvesta reikšmė.');
Labels.set('validation', 'month', 'Įveskite teisingą mėnesį.');
Labels.set('validation', 'datetime-local', 'Įveskite datą ir laiką.');
Labels.set('validation', 'custom', 'Patvirtinimo klaida..');
Labels.set('validation', 'pattern', 'Įveskite reikšmę teisingu formatu.');
Labels.set(
    'validation',
    'rangeOverflow',
    'Reikšmė turi būti mažesnė arba lygi %s.'
);
Labels.set(
    'validation',
    'rangeUnderflow',
    'Reikšmė turi būti didesnė arba lygi %s.'
);
Labels.set(
    'validation',
    'step',
    'Žingsnis turi būti %s, artimiausia reikšmė yra %s ir %s.'
);
Labels.set(
    'validation',
    'long',
    'Reikšmė per ilga, maksimalus ilgis yra %s simboliai (-ių).'
);
Labels.set(
    'validation',
    'short',
    'Reikšmė per trumpa, minimalus ilgis yra %s simboliai (-ių).'
);
Labels.set('validation', 'type', 'Įveskite teisingą reikšmę.');
Labels.set('validation', 'email', 'Įveskite el. pašto adresą.');
Labels.set('validation', 'url', 'Įveskite puslapio nuorodą.');
Labels.set('validation', 'value', 'Užpildykite lauką.');

// User defined rule validations
Labels.set('validation', 'matches', 'Laukų reikšmės nesutampa.');
Labels.set('validation', 'checkboxes', 'Prašome pasirinkti bent %s variantą.');
Labels.set('validation', 'remote', 'Serverio klaida.');
Labels.set('validation', 'maxfilesize', 'File(s) size limit exceeded %s Kb.'); // TODO: translate
Labels.set(
    'validation',
    'requiredMultiple',
    'Prašome pasirinkti bent %s variantą'
);
